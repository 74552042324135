module.exports = [{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134607587-1"},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/vercel/workpath0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
